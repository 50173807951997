import { TransformDaysLeft } from '@/api/types/user.types';

// =================================================================

export interface ExtractorData<T> {
  id: string | number;
  item: T;
}

export interface NormalizedData<T> {
  ids: (string | number)[];
  pool: Record<string | number, T>;
}

export const normalizeData = <M, T>(
  dataArray: Array<M>,
  extractor: (item: M) => ExtractorData<T>,
): NormalizedData<T> => {
  const normalizedData: NormalizedData<T> = { ids: [], pool: {} };

  dataArray.forEach(element => {
    const extractorData = extractor(element);
    if (extractorData) {
      const { id, item } = extractorData;
      normalizedData.ids.push(id);
      normalizedData.pool[id] = item;
    }
  });

  return normalizedData;
};

// =================================================================

export const normalizeSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');

// =================================================================

export const getInitialsFromName = (name: string | undefined) => {
  if (typeof name === 'undefined') {
    return '';
  }

  return name
    .split(' ')
    .slice(0, 2)
    .map(chunk => chunk[0])
    .join('')
    .toUpperCase();
};

// =================================================================

export const getRemainingDays = (timestamp: number): TransformDaysLeft => {
  const remainingDays = { days: 0, hours: '', minutes: '', hasOverOneDay: false };

  if (timestamp === 0) return remainingDays;
  const currentTimestamp = Math.floor(Date.now() / 1000); // seconds
  const difference = timestamp - currentTimestamp;
  remainingDays.hasOverOneDay = difference > 86400;
  remainingDays.days = Math.floor(difference / 86400);
  remainingDays.hours = ('00' + Math.floor(difference / 3600)).slice(-2);
  remainingDays.minutes = (
    '00' + Math.floor((difference - Number(remainingDays.hours) * 3600) / 60)
  ).slice(-2);

  return remainingDays;
};

// =================================================================

export const formatWordEnding = (n: number, titles: string[]) => {
  let index;
  if (n % 10 === 1 && n % 100 !== 11) {
    index = 0;
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    index = 1;
  } else {
    index = 2;
  }
  return titles[index];
};

// =================================================================

export const normalizePrise = (prise: string | number | null) => {
  return prise && `${prise}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

// =================================================================

export const normalizePhone = (phone: string) => {
  return `${phone}`.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
};

// =================================================================

export const commaSeparatedValues = (
  values: (string | null | undefined | false | number)[],
): string => {
  const validValues = values.filter(Boolean);

  return validValues.join(', ');
};

// =================================================================

export const convertDate = (inputDate: string, formatDate: 'YYYY-MM-DD' | 'DD-MM-YYYY'): string => {
  const [part1, part2, part3] = inputDate.split('-');

  const [year, month, day] =
    part1.length === 4
      ? [part1, part2, part3] // YYYY-MM-DD format
      : [part3, part2, part1]; // DD-MM-YYYY format

  return formatDate === 'YYYY-MM-DD' ? `${year}-${month}-${day}` : `${day}-${month}-${year}`;
};

// =================================================================

export const normalizeRating = (rating: number) => {
  if (Number.isInteger(rating)) return rating + '.0';
  else return rating;
};
